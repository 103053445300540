
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import productservice from "@/services/product-service";
import ProjectService from "@/services/project-service";
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    PopUp,
    Loader
  },
})
export default class StockList extends Vue {
  public obj: any = {};
  public currentItem: any = null;
  public currentIndex: number = -1;
  public title: string = "";
  // public rows = 100;

  public currentPage = 1
  public items: any = []
  public master_items: any = [];
  public transfer_items: any = [];


  public ddl_item: any = []
  public ddl_item_code: any = {};

  public from_ddl_project: any = []
  from_ddl_project_code: any = {};

  public to_ddl_project: any = []
  to_ddl_project_code: any = {};
  error: any = "";
  selected_employees: any = {
    employee_name: [],
    project_incharge: [],
    received_by: [],
  };

  loading = false;
  public delivery_doc: any = null;
  get rows() {
    return this.master_items.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 10;
    const end = start + 10;
    return this.master_items.slice(start, end);
  }
  text = ''
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  retrieve() {
    this.loading = true;
    ProjectService.get_list()
      .then((response) => {
        this.items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  // modal end
  async create_stock() {
    let data = this.obj;
    data['to_project_title'] = this.to_ddl_project_code['title']
    // data['to_project'] = this.to_ddl_project_code['id']
    data['to_project'] = this.to_ddl_project[0].id;
    // data['from_project_title']  = this.from_ddl_project_code['title']
    // data['from_project']  = this.from_ddl_project_code['id']
    data['type'] = 'Inn'
    let params = {
      master: data,
      items: this.transfer_items
    }
    const keyNameMapping = {
      title: 'To Location/WH',
      employee_name: 'Employee Name',
      invoice_no: 'Invoice # /Delivery Note',
      entrance_date: 'Entrance Date',
      po: 'PO #',
      project_incharge: 'Project In Charge',
      transported_by: 'Transported Store By',
      received_by: 'Received By',
      remarks: 'Remarks'
    };
    this.error = ""
    const error_list = utils.getEmptyKeys(this.obj, ['employee_name', 'invoice_no', 'entrance_date', 'po', 'project_incharge', 'transported_by', 'received_by', 'remarks'])
    const error_list_1 = utils.getEmptyKeys(this.to_ddl_project_code, ['title'])
    const combinedErrorList = [...error_list_1, ...error_list];

    if (combinedErrorList.length > 0 || !this.transfer_items || Object.keys(this.transfer_items).length === 0) {
      this.error = utils.generateErrorMessageFields(combinedErrorList.concat(!this.transfer_items || Object.keys(this.transfer_items).length === 0 ? 'Add Item in Item Table' : []), keyNameMapping)
    }
    else {
      const response = await productservice.create_item_stock(params);
      if (this.delivery_doc !== null) {
        const file_resp = await productservice.upload_file_in_stock(this.delivery_doc, response.data.id)
      }

      if (response.status === 200) {
        this.obj = {};
        this.showModal("Created Successfully", [], "success")
        let id = response.data['id']
        this.$router.push({ path: `/product/stock/list-in-print/${id}` });
        this.to_ddl_project_code = {};
        this.to_ddl_project = [];
        this.from_ddl_project_code = {};
        this.from_ddl_project = [];
        this.master_items = [];
        this.transfer_items = [];
      }
      else {
        this.showModal('An unexpected error occurred', [], "error");
      }

    }
  }

  refreshList() {
    this.get_items();
    this.currentItem = null;
    this.currentIndex = -1;
  }


  from_search_project(title: any) {
    if (title.length > 4) {
      ProjectService.getByTitle(title)
        .then((response) => {
          this.from_ddl_project = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) { this.from_ddl_project = []; }
  }

  from_get_project(data: any) {
    this.from_ddl_project_code = data;
    if (this.from_ddl_project_code) { this.from_ddl_project = []; }
  }


  to_search_project(title: any) {
    // if (title.length > 2) {
    ProjectService.getByTitle(title)
      .then((response) => {
        this.to_ddl_project = response.data.result;
        // this.to_get_project(this.to_ddl_project)
      })
      .catch((e) => {
        console.log(e);
      });
    // }
    // if (title.length == 0) { this.to_ddl_project = []; }
  }

  to_get_project(data: any) {
    this.to_ddl_project_code = data;
    if (this.to_ddl_project_code) { this.to_ddl_project = []; }
  }

  search_item(title: any) {
    if (title.length > 2) {
      productservice.getByTitle(title)
        .then((response) => {
          this.master_items = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) { this.master_items = []; }
  }

  get_items() {
    productservice.get_item()
      .then((response) => {
        this.master_items = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  isItemInTransfer(item: any) {
    return this.transfer_items.some((transferItem: any) => transferItem.sku === item.sku);
  }
  add_item_to_transfer(data: any) {
    if (!this.isItemInTransfer(data)) {
      data['qty'] = 0
      this.transfer_items.push(data)
    } else {
      this.showModal("Item is already added to the transfer table.Please increase the quantity for the particular item.", [], "error");
    }

  }
  deleteItem(index: any) {
    this.transfer_items.splice(index, 1);
  }
  mounted() {
    // this.get_items();
    this.obj = {};
    this.retrieve();
  }
  reset() {
    this.obj = {};
    this.to_ddl_project_code = {};
    this.error = '';
    this.transfer_items = []
    this.clearTable();
  }
  search_employee(title: any, fieldType: any) {
    if (title.length > 2) {
      this.loading = true;
      UserManagementService.get_Employee_by_Title(title)
        .then((res) => {
          this.selected_employees[fieldType] = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    }
    if (title.length == 0) {
      this.selected_employees[fieldType] = [];
    }
  }
  get_data(data: any, fieldType: any) {
    if (fieldType === 'employee_name') {
      this.obj.employee_name = data.title;
    } else if (fieldType === 'project_incharge') {
      this.obj.project_incharge = data.title;
    } else if (fieldType === 'received_by') {
      this.obj.received_by = data.title;
    } else if (fieldType === 'transported_by') {
      this.obj.transported_by = data.title;
    }
    this.selected_employees = [];
  }
  clearTable() {
    this.master_items = [];
    this.text = ''
  }
}
